/* Redux */
import { configureStore } from '@reduxjs/toolkit';

/* Components */
import { AppReducer } from '../Reducers/AppReducer';

var _store = null;

export const createAppStore = () => {
    const _configureStore = configureStore({
        reducer: AppReducer
    });
    _store = _configureStore;
    return _configureStore;
};

export const getStoreDispatch = () => {
    return _store;
};