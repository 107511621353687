/* Types */
import { SettingsActionTypes } from './SettingsActionTypes';

/* Helpers */
import { getLocalStorage } from '../../Helpers/storage';
import { setDefaultMarkets } from '../../Helpers/defaultMarkets';

var __settings = null;
export const getApplicationSettings = () => {
    return __settings;
};

export const convertLSportIdToBetradarSportId = (id) => {
    switch (id) {
        case 6046: return 1;
        case 48242: return 2;
        case 154914: return 3;
        case 35232: return 4;
        case 530129: return 4;
        case 54094: return 5;
        case 35709: return 6;
        case 35706: return 7;
        case 687894: return 8;
        case 687889: return 9;
        case 154919: return 10;
        case 1149093: return 11;
        case 274791: return 12;
        case 46957: return 15;
        case 131506: return 16;
        case 1149099: return 17;
        case 262622: return 19;
        case 265917: return 20;
        case 452674: return 21;
        case 154923: return 22;
        case 154830: return 23;
        case 388764: return 26;
        case 1149100: return 27;
        case 307126: return 28;
        case 687887: return 29;
        case 1149121: return 30;
        case 1149113: return 32;
        case 687897: return 33;
        case 621569: return 34;
        case 1149097: return 35;
        case 1149103: return 36;
        case 1149104: return 37;
        case 1149120: return 39;
        case 1149094: return 40;
        case 261354: return 43;
        case 1149101: return 44;
        case 1149118: return 48;
        case 1149122: return 54;
        case 687888: return 55;
        case 687893: return 58;
        case 274792: return 59;
        case 687896: return 67;
        case 1149095: return 70;
        case 1149125: return 71;
        case 1149115: return 81;
        case 1149098: return 90;
        case 1149107: return 99;
        case 1149108: return 1149108;
        case 687890: return 107;
        case 1149112: return 108;
        case 1149119: return 129;
        case 687895: return 131;
        case 1149117: return 136;
        case 1149123: return 138;
        case 1149105: return 155;
        case 1149102: return 190;
        case 291987: return 291987;
        case 389537: return 389537;
        case 1149096: return 1149096;
        case 1149109: return 1149109;
        case 1149110: return 1149110;
        case 1149111: return 1149111;
        case 1149114: return 1149114;
        case 1149124: return 1149124;
        case 1149126: return 1149126;
        default:
            return id;
    }
};

const defaultUI = {};

const initialState = {
    application: null,
    sports: getLocalStorage('sports'),
    games: getLocalStorage('games') ?? { favourites: {} },
    screen: {
        mode: document.getElementsByTagName('html')[0].getAttribute('data-theme'),
        width: window.innerWidth,
        height: window.innerWidth,
        mobile: window.innerWidth < 768,
        tablet: window.innerWidth < 991
    },
    balanceVisible: true,
    language: window.__l,
    ui: getLocalStorage('ui') ?? defaultUI
};

// REDUCER
export const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SettingsActionTypes.APPLICATION:
            __settings = { 
                ...state, ...{ application: action.payload }
            };

            var defaultMarkets = null;

            try {
                defaultMarkets = action.payload.defaultMarkets ? JSON.parse(action.payload.defaultMarkets) : null;
            } catch (err) {
                //
            }

            setDefaultMarkets(defaultMarkets);

            try {
                // prepare events
                __settings.application.topEvents?.map(x => {
                    x.sportId = convertLSportIdToBetradarSportId(x.sportId);
                });

                if (__settings.application.sportSetup?.events) {
                    __settings.application.sportSetup.events = __settings.application.sportSetup?.events ? JSON.parse(__settings.application.sportSetup?.events) : {};
                    __settings.application.sportSetup.disabledEvents = {};
                    Object.values(__settings.application.sportSetup.events).map(x => {
                        if (!x.enabled) {
                            __settings.application.sportSetup.disabledEvents[x.id] = true;
                        }
                    });
                }

                if (__settings.application.sportSetup?.sports) {
                    __settings.application.sportSetup.sports = __settings.application.sportSetup?.sports ? JSON.parse(__settings.application.sportSetup?.sports) : {};
                    var convertedSports = {};
                    if (window.fprovider == 2) {
                        Object.keys(__settings.application.sportSetup.sports).map(x => {
                            var sportId = convertLSportIdToBetradarSportId(parseInt(x));
                            __settings.application.sportSetup.sports[x].id = sportId;
                            convertedSports[sportId] = __settings.application.sportSetup.sports[x];
                        });

                        __settings.application.sportSetup.sports = convertedSports;
                    }
                }

                if (__settings.application.sportSetup?.regions) {
                    var convertedRegions = {};
                    __settings.application.sportSetup.regions = __settings.application.sportSetup?.regions ? JSON.parse(__settings.application.sportSetup?.regions) : {};
                    if (window.fprovider == 2) {
                        Object.keys(__settings.application.sportSetup.regions).map(x => {
                            var sportId = convertLSportIdToBetradarSportId(parseInt(x));
                            convertedRegions[sportId] = __settings.application.sportSetup.regions[x];
                        });

                        __settings.application.sportSetup.regions = convertedRegions;
                    }

                    __settings.application.sportSetup.regions = __settings.application.sportSetup?.regions ? JSON.parse(__settings.application.sportSetup?.regions) : {};
                }

                if (__settings.application.sportSetup?.leagues) {
                    __settings.application.sportSetup.leagues = __settings.application.sportSetup?.leagues ? JSON.parse(__settings.application.sportSetup?.leagues) : {};
                }

                // prepare top leagues
                if (__settings.application.topLeagues) {
                    switch (window.fprovider) {
                        case 2:
                            __settings.application.topLeagues.map((x) => {
                                x.sportId = convertLSportIdToBetradarSportId(x.sportId);
                            });
                            break;
                    }
                    __settings.application.topLeaguesDictionary = {};
                    __settings.application.topLeagues.map((x, index) => {
                        __settings.application.topLeaguesDictionary[x.leagueId] = index + 1;
                    });
                }
            } catch (err) {
                //
            }
            return __settings;
        default:
            return state;
    }
};

