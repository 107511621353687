import './_home-page.scss';
import React, { useEffect, useState } from 'react';

export const HomePage = () => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        window.ppp = {};
        window.ppp.onReady = () => {
        };
        try {
            var exists = document.getElementById('ppp-script');
            if (!exists) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.id = 'ppp-script';
                script.async = true;
                script.onload = () => {
                    setReady(true);
                };
                const url = '//cdn-elb.tentangle.com/www/particles/particles.v2.js';
                script.src = url;
                document.body.appendChild(script);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);


    console.log(ready);

    return <div className='page'>
        <div className='brand'>
            <img src='//cdn-elb.tentangle.com/brand/brand-new.png' />
        </div>
        <div className='container'>
        </div>
    </div>;
};