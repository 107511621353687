export const GetDefaultSportMarketSelections = (sportId, live) => {
    var defaultMarket = DefaultMarkets()[sportId];
    if (!defaultMarket) {
        defaultMarket = {
            preMatch: [
                {
                    '1': {
                        title: 'Full Time',
                        selectionTitle: '1,x,2'
                    }
                }
            ],
            live: [
                {
                    '1': {
                        title: 'Full Time',
                        selectionTitle: '1,x,2'
                    }
                }
            ]
        };
    }

    if (live) return defaultMarket.live;
    return defaultMarket.preMatch;
};


let _defaultMarkets = null;

export const setDefaultMarkets = (d) => {
    _defaultMarkets = d ?? defaultConfiguration;
};

export const DefaultMarkets = () => _defaultMarkets;

const defaultConfiguration = {
    '1': {
        'name': 'soccer',
        'preMatch': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1,
                'weight': 1
            },
            '10': {
                'title': 'Double Chance',
                'selectionTitle': '1X,XX,2X',
                'typeId': 10,
                'weight': 2
            },
            '60': {
                'title': '1st Half',
                'selectionTitle': '1,x,2',
                'typeId': 60,
                'weight': 3
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'list': ['Total Goals %1', 'Total Goals %1', 'Total Goals %1'],
                'specifiers': ['total=2.5', 'total=1.5', 'total=3.5'],
                'selectionTitle': 'Under,Over',
                'typeId': 18
            }
        },
        {
            '29': {
                'title': 'Both Teams To Score',
                'selections': ['yes', 'no'],
                'selectionTitle': 'Yes,No',
                'weight': 1,
                'typeId': 29
            },
            '11': {
                'title': 'Draw No Bet',
                'selectionTitle': 'Home Team,Away Team',
                'weight': 2,
                'typeId': 11
            },
            '37': {
                'title': '1x2 and Total',
                'filter': ['{$competitor1} & over {total}', '{$competitor2} & over {total}'],
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'weight': 3,
                'typeId': 37
            }
        }
        ],
        'live': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1,
                'weight': 1
            },
            '10': {
                'title': 'Double Chance',
                'selectionTitle': '1X,XX,2X',
                'typeId': 10,
                'weight': 2
            },
            '8': {
                'title': 'Next Goal',
                'selectionTitle': '1,No Goal,2',
                'typeId': 8,
                'weight': 3
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'list': ['Total Goals %1', 'Total Goals %1', 'Total Goals %1'],
                'specifiers': ['total=1.5', 'total=2.5', 'total=3.5'],
                'selectionTitle': 'Under,Over',
                'typeId': 18
            }
        },
        {
            '29': {
                'title': 'Both Teams To Score',
                'selections': ['yes', 'no'],
                'selectionTitle': 'Yes,No',
                'weight': 1,
                'typeId': 29
            },
            '11': {
                'title': 'Draw No Bet',
                'selectionTitle': 'Home Team,Away Team',
                'weight': 2,
                'typeId': 11
            },
            '37': {
                'title': '1x2 and Total',
                'filter': ['{$competitor1} & over {total}', '{$competitor2} & over {total}'],
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'weight': 3,
                'typeId': 37
            }
        }
        ]
    },
    '2': {
        'name': 'basketball',
        'preMatch': [{
            '219': {
                'title': 'Winner (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 219
            }
        },
        {
            '225': {
                'title': 'Total (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 225
            }
        },
        {
            '223': {
                'title': 'Handicap (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 223
            }
        }
        ],
        'live': [{
            '219': {
                'title': 'Winner (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 219
            }
        },
        {
            '225': {
                'title': 'Total (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 225
            }
        },
        {
            '223': {
                'title': 'Handicap (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 223
            }
        }

        ]
    },
    '3': {
        'name': 'baseball',
        'preMatch': [{
            '251': {
                'title': 'Winner (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 251,
                'weight': 1
            },
            '1123': {
                'title': '1st Inning - Winner',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 1123,
                'weight': 2
            }
        },
        {
            '258': {
                'title': 'Total (Inc. Extra innings)',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 258,
                'weight': 1
            },
            '260': {
                'title': 'Home total (Inc. Extra innings)',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 260,
                'weight': 2
            },
            '261': {
                'title': 'Away total (Inc. Extra innings)',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 261,
                'weight': 3
            }
        },
        {
            '256': {
                'title': 'Handicap (Inc. Extra innings)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 256,
                'weight': 1
            },
            '275': {
                'title': 'Innings 1 to 5 - Handicap',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 275,
                'weight': 2
            },
            '276': {
                'title': 'Innings 1 to 5 - Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 276,
                'weight': 3
            }
        }
        ],
        'live': [{
            '251': {
                'title': 'Winner (Inc. Ovt)',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 251
            }
        },
        {
            '258': {
                'title': 'Total (Inc. Extra innings)',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 258
            }
        },
        {
            '256': {
                'title': 'Handicap (Inc. Extra innings)',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 256
            }
        }
        ]
    },
    '4': {
        'name': 'ice-hockey',
        'preMatch': [{
            '406': {
                'title': 'Winner (Inc. Ovt & Penalties)',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 406,
                'weight': 1
            },
            '11': {
                'title': 'Draw No Bet',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 11,
                'weight': 2
            },
            '220': {
                'title': 'Overtime?',
                'selectionTitle': 'Yes,No',
                'typeId': 220,
                'weight': 3
            }

        },
        {
            '412': {
                'title': 'Total (Inc. Ovt & Penalties)',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 412,
                'weight': 1
            },
            '19': {
                'title': 'Home Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 19,
                'weight': 2
            },
            '20': {
                'title': 'Away Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 20,
                'weight': 3
            }
        },
        {
            '29': {
                'title': 'Both Teams to Score',
                'selectionTitle': 'Yes,No',
                'weight': 1,
                'typeId': 29
            },
            '410': {
                'title': 'Handicap (Inc. Ovt & Penalties)',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'weight': 2,
                'typeId': 410
            },
            '419': {
                'title': 'Odd/Even (Inc. Ovt & Penalties)',
                'selectionTitle': 'Odd,Even',
                'weight': 3,
                'typeId': 419
            }
        }
        ],
        'live': [{
            '406': {
                'title': 'Winner (Inc. Ovt & Penalties)',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 406
            }

        },
        {
            '412': {
                'title': 'Total (Inc. Ovt & Penalties)',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 412
            }
        },
        {
            '410': {
                'title': 'Handicap (Inc. Ovt & Penalties)',
                'selectionTitle': 'Under,Over',
                'filter': ['{$competitor1} ({+hcp})', '{$competitor1} ({-hcp})'],
                'displaySpecifierTitles': true,
                'typeId': 410
            }
        }
        ]
    },
    '5': {
        'name': 'tennis',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Player 1,Player 2',
                'typeId': 186,
                'weight': 1
            },
            '202': {
                'title': 'Set Winner',
                'list': ['1st set - winner', '2nd set - winner'],
                'specifiers': ['setnr=1', 'setnr=2'],
                'selectionTitle': 'Player 1,Player 2',
                'typeId': 202,
                'weight': 2
            }

        },
        {
            '314': {
                'title': 'Total Sets',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 314,
                'weight': 3
            },
            '189': {
                'title': 'Total Games',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 189,
                'weight': 2
            },
            '204': {
                'title': '1st Set Games',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 204,
                'weight': 1
            }
        },
        {
            '190': {
                'title': 'Home Total Games',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 190,
                'weight': 1
            },
            '191': {
                'title': 'Away Total Games',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 191,
                'weight': 2
            },
            '198': {
                'title': 'Odd/Even Games',
                'selectionTitle': 'Odd,Even',
                'typeId': 198,
                'weight': 3
            }
        }
        ],
        'live': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Player 1,Player 2',
                'typeId': 186,
                'weight': 1
            },
            '202': {
                'title': 'Next Set Winner',
                'selectionTitle': 'Player 1,Player 2',
                'typeId': 202,
                'weight': 2
            },
            '195': {
                'title': 'Will there be tiebreak',
                'selectionTitle': 'Yes,No',
                'typeId': 195,
                'weight': 3
            }
        },
        {
            '314': {
                'title': 'Total Sets',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 314,
                'weight': 1
            },
            '189': {
                'title': 'Total Games',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 189,
                'weight': 2
            },
            '1055': {
                'title': 'Winner & Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 1055,
                'weight': 3
            }
        },
        {
            '190': {
                'title': 'Home Total Games',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 190,
                'weight': 1
            },
            '191': {
                'title': 'Away Total Games',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 191,
                'weight': 2
            },
            '198': {
                'title': 'Odd/Even Games',
                'selectionTitle': 'Odd,Even',
                'typeId': 198,
                'weight': 3
            }
        }
        ]
    },
    '6': {
        'name': 'handball',
        'preMatch': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18
            }
        },
        {
            '16': {
                'title': 'Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'filter': ['{$competitor1} ({+hcp})', '{$competitor2} ({-hcp})'],
                'typeId': 16
            }
        }
        ],
        'live': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1,
                'weight': 1
            },
            '10': {
                'title': 'Double Chance',
                'selectionTitle': '1X,XX,2X',
                'typeId': 10,
                'weight': 2
            },
            '14': {
                'title': '1st Half',
                'selectionTitle': '1,x,2',
                'filter': ['{$competitor1} & over {total}', '{$competitor2} & over {total}'],
                'displaySpecifierTitles': true,
                'typeId': 14,
                'weight': 3
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18,
                'weight': 1
            },
            '19': {
                'title': 'Home Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 19,
                'weight': 2
            },
            '20': {
                'title': 'Away Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 20,
                'weight': 3
            }
        },
        {
            '16': {
                'title': 'Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 16,
                'weight': 1
            },
            '26': {
                'title': '1st Half Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 26,
                'weight': 2
            },
            '11': {
                'title': '1st Half Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 11,
                'weight': 3
            }
        }
        ]
    },
    '7': {
        'name': 'floorball',
        'preMatch': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18
            }
        }
        ],
        'live': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1,
                'weight': 1
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18,
                'weight': 1
            }
        }
        ]
    },
    '10': {
        'name': 'boxing',
        'preMatch': [{
            '186': {
                'title': 'Full Time',
                'selectionTitle': '1,2',
                'typeId': 186
            }
        }],
        'live': [{
            '186': {
                'title': 'Full Time',
                'selectionTitle': '1,2',
                'typeId': 186
            }
        }]
    },
    '12': {
        'name': 'rugby',
        'preMatch': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1
            }
        },
        {
            '18': {
                'title': 'Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18
            }
        },
        {
            '11': {
                'title': 'Draw No Bet',
                'selectionTitle': 'Home Team,Away Team',
                'weight': 2,
                'typeId': 11
            }
        }
        ],
        'live': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,2',
                'typeId': 186
            }
        }]
    },
    '29': {
        'name': 'futsal',
        'preMatch': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18
            }
        },
        {
            '29': {
                'title': 'Both Teams To Score',
                'selections': ['yes', 'no'],
                'selectionTitle': 'Yes,No',
                'weight': 1,
                'typeId': 29
            }
        }
        ],
        'live': [{
            '1': {
                'title': 'Full Time',
                'selectionTitle': '1,x,2',
                'typeId': 1
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18
            }
        },
        {
            '29': {
                'title': 'Both Teams To Score',
                'selections': ['yes', 'no'],
                'selectionTitle': 'Yes,No',
                'weight': 1,
                'typeId': 29
            },
            '11': {
                'title': 'Draw No Bet',
                'selectionTitle': 'Home Team,Away Team',
                'weight': 2,
                'typeId': 11
            }
        }
        ]
    },
    '34': {
        'name': 'beach volley',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 186,
                'weight': 1
            },
            '202': {
                'title': 'Set Winner',
                'list': ['1st set - winner', '2nd set - winner'],
                'specifiers': ['setnr=1', 'setnr=2'],
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 202,
                'weight': 2
            }

        },
        {
            '238': {
                'title': 'Total Points',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 238,
                'weight': 1
            },
            '310': {
                'title': '1st Set Total Points',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 310,
                'weight': 2
            },
            '196': {
                'title': 'Exact Sets',
                'selectionTitle': '5:34,5:35,5:36',
                'typeId': 196,
                'weight': 3
            }
        },
        {
            '237': {
                'title': 'Point Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 237,
                'weight': 1
            },
            '309': {
                'title': '1st Set Point Handicap',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 309,
                'weight': 2
            },
            '311': {
                'title': '1st Set Odd/Even',
                'selectionTitle': 'Odd/Even',
                'typeId': 311,
                'weight': 3
            }
        }
        ],
        'live': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 186
            }
        },
        {
            '238': {
                'title': 'Total Points',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 238
            }
        },
        {
            '237': {
                'title': 'Point Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 237
            }
        }
        ]
    },
    '13': {
        'name': 'aussie',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 186
            }
        },
        {
            '18': {
                'title': 'Total Goals',
                'displaySpecifierTitles': true,
                'selectionTitle': 'Under,Over',
                'typeId': 18
            }
        },
        {
            '16': {
                'title': 'Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 16
            }
        }
        ],
        'live': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': '1,2',
                'typeId': 186
            }
        }]
    },
    '16': {
        'name': 'american football',
        'preMatch': [{
            '219': {
                'title': 'Winner',
                'selectionTitle': 'Home Away',
                'typeId': 219
            }
        },
        {
            '225': {
                'title': 'Total Including Over time',
                'displaySpecifierTitles': true,
                'selectionTitle': 'Under,Over',
                'typeId': 225
            }
        },
        {
            '223': {
                'title': 'Handicap Including Over time',
                'displaySpecifierTitles': true,
                'filter': ['{$competitor1} ({+hcp})', '{$competitor2} ({-hcp})'],
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 223
            }
        }
        ],
        'live': [{
            '219': {
                'title': 'Full Time Score',
                'selectionTitle': 'Home Away',
                'typeId': 219
            }
        },
        {
            '225': {
                'title': 'Total Including Over time',
                'displaySpecifierTitles': true,
                'selectionTitle': 'Under,Over',
                'typeId': 225
            }
        },
        {
            '223': {
                'title': 'Total Including Over time',
                'displaySpecifierTitles': true,
                'filter': ['{$competitor1} ({+hcp})', '{$competitor2} ({-hcp})'],
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 223
            }
        }
        ]
    },
    '17': {
        'name': 'cycling',
        'preMatch': [{
            '539': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 186
            }
        }],
        'live': [{
            '539': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 186
            }
        }]
    },
    '20': {
        'name': 'table tennis',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Player 1,Player 2',
                'typeId': 186
            }
        }],
        'live': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Player 1,Player 2',
                'typeId': 186
            }
        }]
    },
    '21': {
        'name': 'cricket',
        'preMatch': [{
            '340': {
                'title': 'Winner (incl. super over)',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 340,
                'weight': 1
            }
        },
        {
            '605': {
                'title': '1st Inning',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 605
            }
        },
        {
            '606': {
                'title': '1st Inning Home Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 606,
                'weight': 1
            },
            '607': {
                'title': '1st Inning Away Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 607,
                'weight': 2
            }
        }
        ],
        'live': [{
            '340': {
                'title': 'Winner (incl. super over)',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 340,
                'weight': 1
            }
        }]
    },
    '22': {
        'name': 'dart',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 186
            }
        },
        {
            '384': {
                'title': 'Total 180s',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 384
            }
        },
        {
            '188': {
                'title': 'Set Handicap',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 188
            }
        }
        ],
        'live': [{
            '1': {
                'title': 'Winner',
                'selectionTitle': '1,x,2',
                'typeId': 1
            }
        }]
    },
    '31': {
        'name': 'badminton',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': '1,2',
                'typeId': 186
            }
        }],
        'live': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': '1,2',
                'typeId': 186
            }
        }]
    },
    '40': {
        'name': 'formula 1',
        'preMatch': [{
            '559': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 186
            }
        }],
        'live': [{
            '559': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 186
            }
        }]
    },
    '190': {
        'name': 'motorcycle racing',
        'preMatch': [{
            '539': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 539
            }
        }],
        'live': [{
            '539': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 539
            }
        }]
    },
    '191': {
        'name': 'motor sport',
        'preMatch': [{
            '559': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 559
            }
        }],
        'live': [{
            '559': {
                'title': 'Head To Head',
                'selectionTitle': 'Competitor',
                'displaySpecifierTitles': true,
                'typeId': 559
            }
        }]
    },
    '117': {
        'name': 'mma',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': '1,x,2',
                'typeId': 186,
                'weight': 1
            },
            '18': {
                'title': 'Total',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 18,
                'weight': 2
            },
            '911': {
                'title': 'Will the fight goto distance',
                'selectionTitle': 'Yes,No',
                'typeId': 911,
                'weight': 3
            }
        }],
        'live': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': '1,x,2',
                'typeId': 186
            }
        }]
    },
    '23': {
        'name': 'voleyball',
        'preMatch': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 186,
                'weight': 1
            },
            '202': {
                'title': 'Set Winner',
                'list': ['1st set - winner', '2nd set - winner'],
                'specifiers': ['setnr=1', 'setnr=2'],
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 202,
                'weight': 2
            }

        },
        {
            '238': {
                'title': 'Total Points',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 238,
                'weight': 1
            },
            '310': {
                'title': '1st Set Total Points',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 310,
                'weight': 2
            },
            '311': {
                'title': '1st Set Odd/Even',
                'selectionTitle': 'Odd,Even',
                'typeId': 311,
                'weight': 3
            }
        },
        {
            '526': {
                'title': 'Will there be a 5th Set',
                'selectionTitle': 'Yes,No',
                'weight': 1,
                'typeId': 526
            },
            '192': {
                'title': 'Home to Win a Set',
                'selectionTitle': 'Yes,No',
                'weight': 2,
                'typeId': 192
            },
            '193': {
                'title': 'Away to Win a Set',
                'selectionTitle': 'Yes,No',
                'weight': 3,
                'typeId': 193
            }
        }
        ],
        'live': [{
            '186': {
                'title': 'Winner',
                'selectionTitle': 'Home Team,Away Team',
                'typeId': 186,
                'weight': 1
            },
            '238': {
                'title': 'Next Set Winner',
                'selectionTitle': 'Under,Over',
                'displaySpecifierTitles': true,
                'typeId': 238,
                'weight': 2
            }
        },
        {
            '202': {
                'title': 'Xth Set Winner',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'typeId': 202
            }
        },
        {
            '237': {
                'title': 'Point handicap',
                'selectionTitle': 'Home Team,Away Team',
                'displaySpecifierTitles': true,
                'filter': ['{$competitor1} ({+hcp})', '{$competitor2} ({-hcp})'],
                'typeId': 237
            }
        }
        ]
    }
};


export const sportDefaultMarkets = {
    2: 219,
    3: 251,
    4: 406,
    5: 186,
    13: 186,
    17: 186,
    20: 186,
    21: 341,
    22: 186,
    23: 186
};

export const sportDefaultMarketsLS = {
    2: 226,
    3: 226,
    4: 1,
    5: 52,
    13: 226,
    17: 52,
    20: 52,
    21: 1,
    22: 52,
    23: 52,
    11: 52
};