/* React */
import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

/* Styles */
import './_application.scss';

/* History */
import { createBrowserHistory } from 'history';

export const appHistory = createBrowserHistory();

export const Application = ({ store, router }) => {
    return (
        <Provider store={store}>
            {router}
        </Provider>
    );
};


export const Modal = {
    open: () => { },
    close: () => { }
};

export const Overlay = {
    open: () => { },
    close: () => { }
};

export const ApplicationVersion = '0.1 beta';

Application.propTypes = {
    history: PropTypes.object,
    store: PropTypes.object,
    router: PropTypes.node
};