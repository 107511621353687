/* Redux */
import { combineReducers } from 'redux';

/* Components */
import { SettingsReducer } from './Settings/SettingsReducer';
import { UserReducer } from './User/UserReducer';

export const AppReducer = combineReducers({
    settings: SettingsReducer,
    user: UserReducer
});