/* React */
import 'regenerator-runtime/runtime';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

/* Styles */
import './_index.scss';

/* Router */
import AppRouter from '../../Router/ApplicationRouter';

/* Application */
import { Application } from '../../Application/Application';

/* Stores */
import { createAppStore } from '../../Stores/AppStore';

const startApp = () => {
    const rootElement = document.getElementById('application');
    const store = createAppStore();
    const root = ReactDOMClient.createRoot(rootElement);

    root.render(<Application store={store} router={<AppRouter />} />);
};

startApp();