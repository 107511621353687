/* Types */
import { UserActionTypes } from './UserActionTypes';

/* Components */


var _user = null;
const initialState = {
    profile: null
};

const BalanceType = {
    Normal: 'Normal',
    Bonus: 'Bonus'
};

export const getUser = () => {
    return _user;
};

// REDUCER
export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.ENABLE_2FA:
            state.profile.authType = 2;
            return { ...state };

        case UserActionTypes.DISABLE_2FA:
            state.profile.authType = 1;
            return { ...state };

        case UserActionTypes.LOGOUT:
            document.cookie = 'gaming-website-auth=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
            state.profile = null;
            window.location.href = '/';
            return state;

        case UserActionTypes.NOTIFICATION:
            if (!state.profile.notifications) state.profile.notifications = [];
            state.profile.notifications.push({
                id: action.payload.Id,
                type: action.payload.Type,
                title: action.payload.Title,
                message: action.payload.Message,
            });
            return { ...state };

        case UserActionTypes.READ_NOTIFICATION:
            state.profile.notifications.find(x => x.id == action.payload).read = true;
            state.profile.notifications = state.profile.notifications.filter(x => !x.read);
            return { ...state };

        case UserActionTypes.BALANCE_TYPE:
            if (!state.profile) return state;
            state.profile.balanceType = action.payload;
            return { ...state };

        case UserActionTypes.BALANCE_UPDATE:
            var balanceData = action.payload.split(':');
            var currency = balanceData[0];
            var balance = balanceData[1];
            var bonus = balanceData[2];

            if (currency != state.profile?.currency) return;

            if (bonus == 'True') {
                state.profile.bonusBalance = balance;
            } else {
                state.profile.balance = balance;
            }
            return { ...state };

        case UserActionTypes.UPDATE_PLAYER_SETTINGS:
            state.profile.settings = action.payload;
            return { ...state };

        case UserActionTypes.USER_LOGIN:
            _user = action.payload;
            state.profile = action.payload;
            window.___c = action.payload.currency;
            state.balanceType = BalanceType.Normal;
            return { ...state };

        default:
            return state;
    }
};