export const UserActionTypes = {
    ENABLE_2FA: 'ENABLE_2FA',
    DISABLE_2FA: 'DISABLE_2FA',
    LOGOUT: 'LOGOUT',
    NOTIFICATION: 'NOTIFICATION',
    READ_NOTIFICATION: 'READ_NOTIFICATION',
    BALANCE_TYPE: 'BALANCE_TYPE',
    BALANCE_UPDATE: 'BALANCE_UPDATE',
    UPDATE_PLAYER_SETTINGS: 'UPDATE_PLAYER_SETTINGS',
    USER_LOGIN: 'USER_LOGIN'
};
