/* React */
import React from 'react';
import { Route, Router, Routes } from 'react-router-dom';

/* History */
import { createMemoryHistory } from 'history';

/* Containers */

/* Socket */
import { HomePage } from '../Pages/HomePage';

const history = createMemoryHistory();

const AppRouter = () => {

    return (
        <Router location={history.location} navigator={history}>
            <Routes>
                <Route path='/' element={<HomePage />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;